<template>
  <section class="container-fluid mt-4">
    <vue-element-loading
      v-if="!ready && !error"
      :active="!ready"
      spinner="spinner"
      color="#ED110E"
      class="position-relative mt-5"
      background-color="transparent"
    />
    <retry v-on:retry="fetchData" v-if="!ready && error"></retry>
    <div class="d-flex justify-content-between" v-if="ready && !error">
      <router-link class="btn btn-red py-1 px-1" :to="{ name: 'peno.swot' }">
        <eva-icon class="eva-red" name="arrow-back-outline"></eva-icon>
        ย้อนกลับ (SWOT)
      </router-link>
      <router-link
        :to="{ name: 'peno.summary' }"
        class="btn btn-action py-1 px-4 mr-3"
      >
        ไปต่อ (ภาพรวม)
        <eva-icon name="arrow-ios-forward" fill="currentColor"></eva-icon>
      </router-link>
    </div>
    <h5 class="font-weight-bold text-left d-flex align-items-baseline">
      แนวคิดโครงการของจังหวัด{{ currentProvince.name_th }}
      <div
        class="text-gray text-medium pointer ml-3"
        v-tooltip="{
          content:
            'ข้อเขียนแนวคิดโครงการเพื่อรองรับแต่ละเป้าหมายด้านพลังงานสำหรับจังหวัด ได้แก่ ชื่อโครงการ, กลุ่มเป้าหมาย, ผลเปลี่ยนแปลงที่ต้องการ/เป้าหมายผลลัพธ์, กิจกรรมหลัก, งบดำเนินโครงการและแหล่งงบที่เหมาะสม',
        }"
      >
        <u>ข้อมูลเพิ่มเติม</u>
      </div>
    </h5>
    <div class="row mt-4" v-if="ready && !error">
      <div class="col-12 col-md-4">
        <div class="box mb-4">
          <div class="box-header">
            <div class="text-ipepp">โปรดคลิกเลือกเป้าหมาย</div>
          </div>
          <div class="box-body d-flex flex-column px-0 pt-0">
            <div
              class="target border-top"
              v-for="(target, index) in targets"
              v-bind:key="target.id"
            >
              <div class="p-3">
                <h6 class="font-weight-bold">
                  เป้าหมายที่ {{ parseInt(index) + 1 }}
                </h6>
                <p class="mb-0">{{ target.title }}</p>
              </div>
              <div class="divider mx-3"></div>
              <div>
                <div
                  class="target-strategy-container"
                  v-for="targetStrategy in target.target_strategies"
                  v-bind:key="targetStrategy.id"
                  @click="selectTargetStrategy(target, targetStrategy)"
                >
                  <div
                    class="target-strategy"
                    :class="{
                      selected: selectedTargetStrategyId == targetStrategy.id,
                    }"
                  >
                    <div>{{ targetStrategy.text }}</div>
                    <div class="d-flex align-items-center mt-1">
                      <div class="text-gray mr-2">SWOT</div>
                      <div
                        class="swot-badge"
                        v-for="swot in targetStrategy.swots"
                        v-bind:key="swot.id"
                      >
                        {{ swot.badge }}
                      </div>
                    </div>
                  </div>
                  <div class="target-strategy-divider"></div>
                </div>
                <div
                  class="text-light-ipepp text-center my-3 text-small"
                  v-if="
                    !target.target_strategies ||
                    !target.target_strategies.length
                  "
                >
                  ยังไม่มีกลยุทธ์
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="box mb-4">
          <div
            class="box-header d-flex align-items-center justify-content-between"
          >
            <div class="text-ipepp">
              โครงการรองรับเป้าหมาย
              <span class="pointer text-blue" @click="showGuidingModal()">
                <u>ดูคำแนะนำ</u>
              </span>
            </div>
            <button
              class="btn py-0 btn-red"
              @click="createProject"
              v-if="canAddProject && allowEdit"
            >
              <eva-icon name="plus"></eva-icon>
              เพิ่มโครงการ
            </button>
            <div class="text-danger" v-else>โปรดเลือกแนวทางสำหรับโครงการ</div>
          </div>
          <div class="box-body d-flex flex-column px-0 pt-0">
            <div v-if="selectedTargetStrategy" class="h5 ml-3 mb-2 mt-3">
              <u class="h6 text-gray">กำลังเลือกกลยุทธ์</u>:
              {{ selectedTargetStrategy.text }}
            </div>
            <div
              class="d-flex align-items-center justify-content-center text-h5"
              style="height: 10rem"
              v-if="filteredProjects.length == 0"
            >
              ยังไม่มีโครงการ
            </div>
            <div
              class="border-bottom"
              v-for="(project, index) in filteredProjects"
              v-bind:key="project.id"
            >
              <div class="row p-3">
                <div class="col-8 border-right border-gray">
                  <div class="font-weight-bold">
                    โครงการที่ {{ parseInt(index) + 1 }}
                  </div>
                  <div class="text-medium mb-2">
                    {{ project.name }}
                  </div>
                  <div class="d-flex mb-2 align-items-baseline">
                    <div class="font-weight-bold mr-2">วงเงิน</div>
                    <ul>
                      <li
                        v-for="budget in project.project_budgets"
                        :key="budget.id"
                      >
                        <strong>ปี {{ budget.year }}</strong>
                        <template v-if="budget.budget_expected">
                          - {{ budget.budget_expected | auto_number }} บาท
                        </template>
                      </li>
                    </ul>
                  </div>
                  <div class="d-flex mb-2 align-items-baseline">
                    <div class="font-weight-bold mr-2">แหล่งงบ</div>
                    <ul>
                      <li
                        v-for="source in project.budget_sources"
                        :key="source.type"
                      >
                        <span
                          v-for="listSource in budgetSources"
                          :key="listSource.id"
                        >
                          <span v-if="listSource.id == source.type">
                            {{ listSource.title }}
                            <span v-if="source.type == 6">
                              ({{ source.other }})
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="mb-2">
                    <span class="font-weight-bold mr-2">กลุ่มเป้าหมาย</span>
                    <span class="text-medium">{{ project.target_group }}</span>
                  </div>
                  <div class="font-weight-bold">กิจกรรมหลัก</div>
                  <ol class="text-medium mb-2">
                    <li
                      v-for="activity in project.project_activities"
                      :key="activity.id"
                    >
                      {{ activity.content }}
                    </li>
                  </ol>
                  <div class="font-weight-bold">ผลลัพธ์สำคัญอื่นๆ</div>
                  <ul>
                    <li
                      class="text-medium"
                      v-for="target_result in project['target-results'].data"
                      v-bind:key="target_result.id"
                    >
                      {{ projectTargetResultDisplay(target_result) }}
                    </li>
                  </ul>
                </div>
                <div class="col-4">
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-between
                      mb-3
                    "
                  >
                    <div class="font-weight-bold">ตัวชี้วัดเชิงพลังงาน</div>
                    <button
                      v-show="allowEdit"
                      class="btn btn-edit py-0 text-red"
                      @click="showProjectEditor(project)"
                    >
                      <eva-icon name="edit-outline"></eva-icon>
                    </button>
                  </div>
                  <div class="mx-3">
                    <div class="d-flex justify-content-between border-bottom border-gray pb-2 text-small">
                      <div class="font-weight-bold text-center">
                        ผลลัพธ์
                      </div>
                      <div class="helper-color text-right">
                        ผลผลิตที่คาดหวัง
                      </div>
                    </div>
                    <div
                      v-for="projectTargetIndicator in project.project_target_indicators"
                      :key="projectTargetIndicator.id"
                    >
                      <div class="row">
                        <div class="col-7">
                          {{
                            energyIndicatorFullText(
                              project,
                              projectTargetIndicator
                            )
                          }}
                        </div>
                        <div class="col-5 helper-color text-right">
                          {{
                            projectTargetIndicator.indicator_value_expected
                              | auto_number(false, 3)
                          }}
                        </div>
                        <!--<div class="col-4 text-right">-->
                        <!--{{ project.indicator_value_result }}-->
                        <!--</div>-->
                      </div>
                      <div class="row reduce-top">
                        <div class="col-12 helper-color text-right">
                          <small
                            v-if="
                              projectTargetIndicator.indicator_toe_value_expected !=
                              null
                            "
                          >
                            ( ≈{{
                              projectTargetIndicator.indicator_toe_value_expected
                                | auto_number(false, 3)
                            }}
                            toe )
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modals-container
      @onSaved="onCreatedProject"
      @onUpdated="onUpdatedProject"
      @onDeleted="onDeletedProject"
    ></modals-container>
  </section>
</template>

<script>
import Retry from '@/components/Retry.vue';
import ProjectEditor from '@/components/ProjectEditor.vue';
import GuidingModal from '@/components/GuidingModal.vue';
import GuidingProject from '@/components/guiding/GuidingProject.vue';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import {get} from 'lodash';

export default {
  name: 'projects',
  data() {
    return {
      error: false,
      ready: false,
      year: 2019,
      selectedTargetStrategyId: 0,
      selectedTargetId: 0,
      projects: null,
    };
  },
  components: {
    Retry,
  },
  created() {
    this.year = new Date().getFullYear();
    this.fetchData();
    // this.selectedTargetStrategyId = this.targets[0].target_strategies[0].id;
    // this.selectedTargetStrategyId = head(head(this.targets).target_strategies).id;
    this.selectedTargetStrategyId = get(
      this,
      'targets[0].target_strategies[0].id'
    );
    this.selectedTargetId = (this.selectedTargetStrategy || []).target_id;
  },
  computed: {
    ...mapState('peno', ['intro', 'currentReport', 'budgetSources']),
    ...mapGetters('peno', [
      'currentProvince',
      'getTargetEconomyOfProject',
      'currentTargets',
      'allowEdit',
    ]),
    targets() {
      return this.currentTargets;
    },
    allTargetStrategies() {
      let strategies = [];
      this.targets.forEach((target) => {
        if (target.target_strategies) {
          target.target_strategies.forEach((strategy) => {
            strategies.push(strategy);
          });
        }
      });
      return strategies;
    },
    selectedTargetStrategy() {
      return this.allTargetStrategies.find((strategy) => {
        return strategy.id == this.selectedTargetStrategyId;
      });
    },
    selectedTarget() {
      return this.targets.find((target) => {
        return target.id == this.selectedTargetId;
      });
    },
    canAddProject() {
      return this.selectedTargetStrategyId && this.selectedTargetId;
    },
    // allProjects() {
    //   let projects = [];
    //   this.targets.forEach(target => {
    //     target.target_results.forEach(targetResult => {
    //       targetResult.projects.forEach(project => {
    //         project['target-results'] = targetResult;
    //         projects.push(project);
    //       });
    //     });
    //   });
    //   return projects;
    // },
    filteredProjects() {
      // return allProjects = this.selectedTargetStrategy.projects;
      return this.projects.filter(
        (project) => project.target_strategy_id == this.selectedTargetStrategyId
      );
    },
  },
  methods: {
    fetchData() {
      if (this.projects == null) {
        this.ready = false;
        this.error = false;
        window.axios
          .get('/api/provinces/' + this.currentProvince.id + '/projects')
          .then(({data}) => {
            this.projects = data.projects;
            this.ready = true;
            this.error = false;
          })
          .catch((error) => {
            this.ready = false;
            this.error = true;
            this.$toasted.global.display_error({
              message: error,
            });
          });
      }
    },
    projectTargetResultDisplay(targetResult) {
      return (
        targetResult.category_title +
        ' - ' +
        targetResult.name +
        ' ' +
        targetResult.expected_value +
        ' ' +
        targetResult.unit
      );
    },
    selectTargetStrategy(target, targetStrategy) {
      this.selectedTargetId = target.id;
      this.selectedTargetStrategyId = targetStrategy.id;
    },
    energyIndicatorFullText(project, project_target_indicator) {
      const target = this.selectedTarget;
      const targetIndicator = target.target_indicators.find(
        (targetIndicator) =>
          targetIndicator.id == project_target_indicator.target_indicator_id
      );
      switch (project.target.type) {
        case 1:
          return (
            targetIndicator.name +
            ' RE (' +
            project_target_indicator.indicator_unit_expected +
            ')'
          );
        case 2:
          return (
            targetIndicator.name +
            ' EE (' +
            project_target_indicator.indicator_unit_expected +
            ')'
          );
        case 3:
          return (
            targetIndicator.name +
            ' ' +
            project.target.type_other +
            ' (' +
            project_target_indicator.indicator_unit_expected +
            ')'
          );
        default:
          return '-';
      }
    },
    showGuidingModal() {
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: GuidingProject,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    createProject() {
      this.$modal.show(
        ProjectEditor,
        {
          name: 'project_editor',
          mode: 'create',
          project: {},
          targetStrategyId: this.selectedTargetStrategyId,
          // targetResults: this.selectedTarget.target_results,
          target: this.selectedTarget,
        },
        {
          name: 'project_editor',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '65%',
          transition: 'fade',
        }
      );
    },
    onCreatedProject(project) {
      this.projects.push(project);
    },
    showProjectEditor(project) {
      this.$modal.show(
        ProjectEditor,
        {
          name: 'project_editor',
          mode: 'edit',
          project: project,
          targetStrategyId: this.selectedTargetStrategyId,
          target: this.selectedTarget,
        },
        {
          name: 'project_editor',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '70%',
          transition: 'fade',
        }
      );
    },
    onUpdatedProject(updatedProject) {
      const findProject = this.projects.find(
        (item) => item.id === updatedProject.id
      );
      const index = this.projects.indexOf(findProject);

      if (index === -1) {
        this.projects.push(updatedProject);
      } else {
        this.projects.splice(index, 1, updatedProject);
      }
    },
    onDeletedProject(deletedProject) {
      this.projects = this.projects.filter((el) => el.id !== deletedProject.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.divider {
  border-bottom: 1px solid #dee2e6;
}

.target-strategy {
  position: relative;
  padding: 1rem 1rem 1rem 2rem;
  cursor: pointer;
  border-right: 6px solid transparent;
  transition: all 0.3s ease-in-out;
}

.target-strategy:before {
  content: '•';
  position: absolute;
  left: 0.8rem;
  top: 1.1rem;
  color: #dee2e6;
}

.target-strategy:hover {
  background: $brand-color-100;
}

.target-strategy.selected {
  background: $brand-color-100;
  border-right-color: $red-color;
}

.target-strategy-container:not(:last-child) .target-strategy-divider {
  margin: 0 1rem 0 2rem;
  border-bottom: 1px solid #dee2e6;
}

.btn-edit {
  border-color: $gray-color-600;
  transition: all 0.3s ease-in-out;
}

.btn-edit:hover {
  background: $gray-color-200;
}

.reduce-top {
  margin-top: -15px;
}
</style>
